export const list = '/list'
export const store = '/store'
export const update = '/update'
export const toggleStatus = '/toggle-status'
export const destroy = '/delete'
// project start
export const projectList = '/project/list'
export const projectStore = '/project/store'
export const projectUpdate = '/project/update'
export const projectToggleStatus = '/project/toggle-status'
export const projectDestroy = '/project/destroy'
// project end
export const reportHeadingList = '/report-heading/list'
export const reportHeadingStore = '/report-heading/store'
export const reportHeadingUpdate = '/report-heading/update'
export const reportToggleStatus = '/report-heading/toggle-status'
// project start
export const schemeFormAffidavitList = '/scheme-form/list'
export const schemeFormAffidavitStore = '/scheme-form/store'
export const schemeFormAffidavitUpdate = '/scheme-form/update'
export const schemeFormAffidavitToggleStatus = '/scheme-form/toggle-status'
export const schemeFormAffidavitDestroy = '/scheme-form/destroy'

export const paymentList = '/payment-type/list'
export const paymentStore = '/payment-type/store'
export const paymentUpdate = '/payment-type/update'
export const paymentToggleStatus = '/payment-type/toggle-status'
export const paymentDestroy = '/payment-type/destroy'
// equipment
export const equipmentList = 'equipment-type/list'
export const equipmentStore = 'equipment-type/store'
export const equipmentUpdate = 'equipment-type/update'
export const equipmentToggleStatus = 'equipment-type/toggle-status'
export const equipmentDelete = 'equipment-type/destroy'
// scheme
export const schemeList = 'scheme-type/list'
export const schemeStore = 'scheme-type/store'
export const schemeUpdate = 'scheme-type/update'
export const schemeToggleStatus = 'scheme-type/toggle-status'
export const schemeDelete = 'scheme-type/destroy'
// sub scheme type
export const subSchemeList = 'sub-scheme-type/list'
export const subSchemeStore = 'sub-scheme-type/store'
export const subSchemeUpdate = 'sub-scheme-type/update'
export const subSchemeToggleStatus = 'sub-scheme-type/toggle-status'
export const subSchemeDelete = 'sub-scheme-type/destroy'
// pump capacity
export const pumpCapacityList = 'pump-capacity/list'
export const pumpCapacityStore = 'pump-capacity/store'
export const pumpCapacityUpdate = 'pump-capacity/update'
export const pumpCapacityToggleStatus = 'pump-capacity/toggle-status'
export const pumpCapacityDelete = 'pump-capacity/destroy'
// item-category
export const categoryListApi = 'item-categories/list'
export const categoryStore = 'item-categories/store'
export const categoryUpdate = 'item-categories/update'
export const categoryToggleStatusApi = 'item-categories/toggle-status'
export const categoryDelete = 'item-categories/destroy'
// item-sub-category
export const subCategoryList = 'item-sub-categories/list'
export const subCategoryStore = 'item-sub-categories/store'
export const subCategoryUpdate = 'item-sub-categories/update'
export const subCategoryToggleStatus = 'item-sub-categories/toggle-status'
export const subCategoryDelete = 'item-sub-categories/destroy'
// Item-Store
export const storeItemList = 'items/list'
export const storeItemStore = 'items/store'
export const storeItemUpdate = 'items/update'
export const storeItemToggleStatus = 'items/toggle-status'
export const storeItemDelete = 'items/destroy'
// Pump Type
export const pumpTypeList = 'pump-type/list'
export const pumpTypeStore = 'pump-type/store'
export const pumpTypeUpdate = 'pump-type/update'
export const pumpTypeToggleStatus = 'pump-type/toggle-status'
export const pumpTypeDestroy = 'pump-type/destroy'
// Pump Progress Type
export const pumpProgressTypeList = 'pump-installation-progress-type/list'
export const pumpProgressTypeListByGroup = 'pump-installation-progress-type/list-by-group'
export const pumpProgressTypeStore = 'pump-installation-progress-type/store'
export const pumpProgressTypeUpdateArrayData = 'pump-installation-progress-type/updateArrayData'
export const pumpProgressTypeUpdate = 'pump-installation-progress-type/update'
export const pumpProgressTypeToggleStatus = 'pump-installation-progress-type/toggle-status'
export const pumpProgressTypeDestroy = 'pump-installation-progress-type/destroy'
// laboratory
export const laboratoryList = 'laboratory/list'
export const laboratoryStore = 'laboratory/store'
export const laboratoryUpdate = 'laboratory/update'
export const laboratoryToggleStatus = 'laboratory/toggle-status'
export const laboratoryDelete = 'laboratory/destroy'
// Contractor

export const contractorList = 'contractor/list'
export const contractorStore = 'contractor/store'
export const contractorUpdate = 'contractor/update'
export const contractorToggleStatus = 'contractor/toggle-status'
export const contractorDelete = 'contractor/destroy'
// Measurement Unit
export const measurementUnitList = 'measurement-units/list'
export const measurementUnitStore = 'measurement-units/store'
export const measurementUnitUpdate = 'measurement-units/update'
export const measurementUnitToggleStatus = 'measurement-units/toggle-status'
export const measurementUnitDelete = 'measurement-units/destroy'
// Horse Power
export const horsePowerList = 'horse-power/list'
export const horsePowerStore = 'horse-power/store'
export const horsePowerUpdate = 'horse-power/update'
export const horsePowerToggleStatus = 'horse-power/toggle-status'
export const horsePowerDelete = 'horse-power/destroy'
// Circle Area
export const circleAreaList = 'master-circle-area/list'
export const circleAreaStore = 'master-circle-area/store'
export const circleAreaUpdate = 'master-circle-area/update'
export const circleAreaToggleStatus = 'master-circle-area/toggle-status'
export const circleAreaDelete = 'master-circle-area/destroy'
// Circle Area
export const waterTestParamList = 'water-testing-parameter/list'
export const waterTestParamStore = 'water-testing-parameter/store'
export const waterTestParamUpdate = 'water-testing-parameter/update'
export const waterTestParamToggleStatus = 'water-testing-parameter/toggle-status'
export const waterTestParamDelete = 'water-testing-parameter/destroy'
// Payment
export const masterPaymentList = 'master-payment/list'
export const masterPaymentStore = 'master-payment/store'
export const masterPaymentUpdate = 'master-payment/update'
export const masterPaymentToggleStatus = 'master-payment/toggle-status'
// Refund Deduction
export const refundDeductStore = 'far-apps-refund-deduct/store'
export const refundDeductList = 'far-apps-refund-deduct/list'

// Irrigation all excel data import
export const irrigationExcelImport = '/irrigation-excel-import'

const irrigationDashboardPrefix = '/irrigation-dashboard'
export const fetchScheme = irrigationDashboardPrefix + '/fetch/scheme'
export const fetchTotal = irrigationDashboardPrefix + '/fetch/total'
export const fetchComplain = irrigationDashboardPrefix + '/fetch/complain'
export const fetchVisitor = irrigationDashboardPrefix + '/fetch/visitor'

export const notificationReceivedList = '/notification-received/list'

// master mouza apis
export const mouzaListApi = '/master-mouza/list'
export const mouzaStoreApi = '/master-mouza/store'
export const mouzaUpdateApi = '/master-mouza/update'
export const mouzaToggleStatus = '/master-mouza/toggle-status'
